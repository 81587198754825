<template>
	<ion-page>
		<div v-show="frameEnabled" v-if="activeCampaign">
			<iframe id="iframeid" :src="`/#/categories/${activeCampaign}`"/>
			<ion-backdrop></ion-backdrop>
		</div>
		<div class="ion-text-center" v-if="shownCard" @click="shownCard = null"> <!-- zatial to funguje ked iba kliknes na kartu treba to dat na cely screen -->
			<div style="position: absolute;">
				<ion-row class="card-hint text-bold position-relative">
					<p>{{ $t("Tap to close") }}</p>
				</ion-row>
				<z-card class="card-preview" :card="shownCard"/>
			</div>
			<ion-backdrop :tappable="true" @ionBackdropTap="shownCard = null"/>
		</div>
		<ion-content>
			<ion-grid class="ion-no-padding notch-margin-top">
				<ion-row class="landing-grid">
					<ion-col class="landing p-0">
            <a-blob :color=" campaign.color1 ?? '#FC7B48'" position="top"/>
            <a-blob :color=" campaign.color2 ?? '#65D7FF'" position="bottom"/>
            <div class="ion-padding">
							<ion-row class="a-header">
								<ion-col size="5">
									<div class="a-logo font-monoxil-bold text-bold" @click="$router.push({name:'Campaigns'})">
										BEEN<br>THERE<br>TOGETHER
									</div>
								</ion-col>
								<ion-col class="ion-text-right" size="4">
									<ion-row class="ion-float-right" style="white-space: nowrap;	">
                    <img class="info-icon" src="../../assets/icon/arrow-left.png" @click="$router.push({name:'Campaigns'})">
                    <ion-icon @click="$router.push({name: 'Info'})" class="info-icon" :icon="helpCircleOutline"></ion-icon>
									</ion-row>
								</ion-col>
								<ion-col size="3" class="ion-text-right">
									<ion-button color="dark" class="a-play-button" @click="onPlay()" > <!-- tu bude podmienka ktora triggerne otvorenie iframu   -->
                    {{ $t("PLAY") }}
									</ion-button>
								</ion-col>
							</ion-row>
							<div class="text1 text-bold font-monoxil-bold" v-html="campaign.campaign_title"></div>
							<div class="text2 text-medium" v-html="campaign.campaign_subtitle"></div>
							<div class="ion-text-center">
								<ion-button class="btn-try-it-out font-monoxil" color="dark" expand="block" @click="onPlay()">{{ $t("Try it out!") }}</ion-button>
								<img class="caret-down" src="@/plugins/assets/icon/caret-down.png">
							</div>
						</div>
					</ion-col>
					<ion-col class="feed ion-padding">
						<h2 class="font-monoxil text-bold">{{ $t("Community feed") }}</h2>
						<h3 class="text-medium">{{ $t("Community feed perex") }}</h3>
						<div v-show="!loading">
							<div class="masonry">
								<div :id="'item' + post.id" class="grid-item" v-for="post in posts" :key="post.id">
									<z-post :id="'z-post'+ post.id" :postId="post.id" :post="post" @showCard="showCard($event)"/>
								</div>
							</div>
							<div class="feed-end ion-text-center">
								<div class="ion-margin-vertical">
									<img class="svg" src="../../assets/svg/chat-icons.svg">
									<h1 class="font-monoxil text-bold">{{ $t("No more posts") }}</h1>
								</div>
								<h3 class="text-medium ion-margin-vertical">{{ $t("No more posts perex") }}</h3>
								<ion-button class="btn-play-the-game font-monoxil text-bold" color="dark" expand="block" @click="$router.push({name:'Categories'})">{{ $t("Play the game!") }}</ion-button>
								<div class="back-to-top text-medium text-underline" @click="scrollToTop()">{{ $t("Back to top!") }}<div>
								</div>
								</div>
							</div>
						</div>
						<div v-show="loading" class="ion-text-center">
							<ion-spinner></ion-spinner>
						</div>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-content>
	</ion-page>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import {mapState} from "vuex"
import { mapFields } from 'vuex-map-fields'
import { helpCircleOutline } from 'ionicons/icons'
import { nextTick } from 'vue'

import card from '@/plugins/app/_components/z-card.vue'
import post from '@/plugins/app/landing/z-feed-post.vue'
import blob from '@/plugins/app/landing/a-blob.vue'

function getContent() {
	return document.querySelector('ion-content')
}

//feed structure: https://budme-tu-spolu-admin.hybridlab.dev/api/v1/feeds
// data [{id: 54},{}] => data[0].id == 54

export default {
	components: {
		'z-post': post,
		'z-card': card,
		'a-blob': blob,
	},
	data(){
		return{
			posts: [],
			shownCard: null,
			loading: true,
			wWidth: ' ',
			mediaQuery: ' ',
			helpCircleOutline,
      msnry: {},
		}
	},
	async mounted() {
    if (!this.activeCampaign)
      this.$router.push({name: 'Campaigns'})

    await this.$store.dispatch('offline/loadCampaignCategories')

    this._loadPosts()
  },
	methods:{
		async _loadPosts(){

			try {
				const {data: {data}} = await axios.get(`/feeds/${this.activeCampaign}`)
				this.posts = data
			} catch (error) {
				alert('Oops! An unsuspected error occurred, intended posts were not able to load. Please try again by reloading the page.')
			}
			setTimeout(()=>{
				this.loading = false
			}, 900)
			setTimeout(()=>{
				this._initMasonryGrid()
			}, 900)
			setTimeout(()=>{
				this._scrollTo()
			}, 1000)
			this.wWidth = window.innerWidth
		},
		showCard(card){
			this.shownCard = card
		},
		formatDate(dateString){
			return moment(dateString).format('MMM DD hh:mm')
		},
		scrollToTop() {
			getContent().scrollToTop(1500)
		},

		onPlay(){
			if(this.wWidth >= 1024){
				this.frameEnabled = true
				// document.getElementById('iframeid').contentWindow.location.reload()
			} else {
				this.$router.push({name: 'Categories'})
			}

		},
		_initMasonryGrid(){
		  if(!document.getElementsByClassName('masonry').length)
		    return

      this.msnry = new Masonry( '.masonry', {
				itemSelector: '.grid-item',
				percentPosition: true,
				transitionDuration: '0'
			})

      setInterval(_ => {
        if(this.msnry.getItemElements().length)
          this.msnry.layout()
      }, 1000)
		},
		printUrl(postId){
			this.$router.push({name: 'Posts', params:{post: postId}})
			console.log(this.$route.params)
		},
		 async _scrollTo(){
			let selector = `#item${this.$route.params.post}`
			const postEl = document.querySelector(selector)
			let selector2 = `#z-post${this.$route.params.post}`
			const animateEl = document.querySelector(selector2)
			if(!postEl) return
			await nextTick()
			animateEl.classList.add('a-active-feed')

			postEl.scrollIntoView({behavior: 'smooth'})
			setTimeout(() => {
				window.history.pushState(null, '', '/#/')
			}, 100)
		},

	},
	computed: {
		...mapFields('offline', ['frameEnabled', 'campaign']),
    ...mapState('offline', ['activeCampaign']),
	},

	watch: {
		wWidth(neww, oldd){
			//console.log(neww, oldd)
		}
	}
}
</script>

<style scoped lang="scss">

.ion-margin-vertical {
	margin: 10vw 0;
}

.card-wrapper {
	min-height: 65vh;
}

.card-hint {
	z-index: 10;
	background-color: black;
	border-radius: 85px;
	color: white;
	width: 68vw;
	margin: 16vw 16vw 9.6vw 16vw;
  justify-content: center;

	img {
		height: 6vw;
		margin: 2vw 0vw 2vw 2vw;
	}

	p {
		font-size: 4.25vw;
		line-height: 6vw;
		font-family: "IBM Plex Mono";
		margin: 2vw;
	}
}

.icon-header {
  height: 6vw;
  max-height: 40px;
}

.card-preview {
	margin: 0 4vw;
}

.text1 {
	font-size: 10.5vw;
	padding: 5vh 0;
}

.text2 {
	font-size: 4.5vw;
	padding: 5vh 0;
}

.btn {
	height: 12vw;
	--border-radius: 4vw;

	&-try-it-out {
		margin: 5vw 0;
		height: 15vw;
		font-size: 5vw;
	}
	&-play-the-game {
		margin: 0 3vw;
		height: 15vw;
		font-size: 5vw;
	}
}

.caret-down {
	width: 10vw;
	display: flex;
	margin-left: auto;
	margin-right: auto
}

h1 {
	font-size: 7.2vw;
}

h2 {
	font-size: 6vw;
}

h3 {
	font-size: 5vw;
}

.back-to-top {
	font-size: 4vw;
	padding: 10vw 3vw;
}

.landing {
	min-height: 100vh;
  overflow: hidden;
}

.landing-grid {
  @media only screen and (max-width:1023px) {
    display: flex;
    flex-direction: column;
  }
}

.feed {
	border-top: 2px solid black;
	background-color: white;
}

.feed-end {

	.svg {
		height: 12vw;
		width: 12vw;
	}
}

.info-icon {
  height: 10vw;
  width: 10vw;
	margin: 5vw 0 0 5vw;
}

 </style>
