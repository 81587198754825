<template>
	<ion-col class="wrapper">
		<div class="share">
			<h1 class="h1">Share</h1>
		</div>
		<img class="close-icon ion-text-center" src="../../assets/svg/close-icon.svg" @click="closeAltShare()"/>
	</ion-col>
</template>

<script>
import { modalController } from '@ionic/core'
import Vue from "vue"

export default {
	data(){
		return {
            
		}
	},
	methods: {
		closeAltShare(){
			modalController.dismiss()
		},
        
	}
}
</script>

<style scoped lang="scss">

.wrapper {
	background-color: #FDD835;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.share {
	background-color: white;
	border: 2px solid black;
	border-radius: 1vw;
	width: 60vw;
	min-height: 20vh;
	position: absolute;
}

.close-icon {
	height: 4vw;
	position: relative;
	top: 30vh
}

h1 {
	margin: 0 !important;
	padding: 1vw !important;
}
</style> 
