<template>
	<div class="card-wrapper position-relative ion-text-left font-monoxil" :style="`background-color: ${card?.category?.color}`">
		<div class="card-activity text-medium">
			{{card.activity}}
		</div>
		<div class="card-text text-bold">
			{{card.description}}
		</div>
		<div class="count-text text-medium position-absolute">
			{{countString}}
		</div>
	</div>
</template>

<script>
export default {
	props:{
		card: {
			required: true,
			type: Object
		},
		countString:{
			required: false,
			type: String,
			default: ''
		}
	}
}
</script>

<style scoped lang="scss">

.card {
	&-wrapper {
		border: 2px solid black;
		min-height: 65vh;
		border-radius: 3.2vw;
		z-index: 10;
		padding: 8vw 5vw;
		//max-width: 90vw;
	}

	&-activity {
		font-size: 5vw;
		padding-bottom: 8vw;
	}

	&-text {
		font-size: 7vw;
	}
}
.count-text {
    bottom: 7vw;
    font-size: 4.5vw;
}
</style>
