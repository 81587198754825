<template>
	<ion-card class="feed-img" v-if="post.image">
		<img class="image" :src="post.image.path" />
		<ion-card-header>
			<ion-card-title class="text-medium">{{ post.caption }}</ion-card-title>
		</ion-card-header>
		<ion-row>
			<ion-col size="6" class="ion-text-center">
				<div class="date">{{ formatDate(post.created_at) }}</div>
			</ion-col>
			<ion-col size="6">
				<ion-row class="ion-float-right" @click="show(post.card)">
					<svg class="svg" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" @click="show(post.card)">
						<path d="M3 16.5L12 21.75L21 16.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M3 12L12 17.25L21 12" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M3 7.5L12 12.75L21 7.5L12 2.25L3 7.5Z" :style="`fill: ${post?.card?.category.color};`" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
					<p class="ion-no-margin text-medium text-black">Card</p>
				</ion-row>
			</ion-col>
		</ion-row>
	</ion-card>
	<!-- Feed iba s textom -->
	<ion-card class="feed-text" v-else>
		<ion-card-header :style="`background-color: ${post?.card?.category.color};`">
			<ion-card-title class="text-medium">{{ post.caption }}</ion-card-title>
		</ion-card-header>
		<ion-row>
			<ion-col size="6">
				<div class="date">{{ formatDate(post.created_at) }}</div>
			</ion-col>
			<ion-col size="6">
				<ion-row class="ion-float-right pointer" @click="show(post.card)">
					<svg class="svg" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" @click="show(post.card)">
						<path d="M3 16.5L12 21.75L21 16.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M3 12L12 17.25L21 12" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M3 7.5L12 12.75L21 7.5L12 2.25L3 7.5Z" :style="`fill: ${post?.card?.category.color};`" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
					<p class="ion-no-margin text-medium text-black">{{ $t("Card") }}</p>
				</ion-row>
			</ion-col>
		</ion-row>
	</ion-card>
</template>

<script>

import moment from 'moment'
import { modalController } from '@ionic/vue'
import FallbackShare from '@/plugins/app/landing/a-fallback-share.vue'
import { Plugins } from '@capacitor/core';
const { Share } = Plugins;


export default {
	// eslint-disable-next-line vue/no-unused-components
	components: {'a-fallback-share' : FallbackShare},
	props: {
		post: {
			required: true,
			type: Object
		},
		postId: {
			required: true,
			type: Number
		}
	},
	data(){
		return {
			aRoute: `${window.document.location.href}posts/${this.postId}`
		}
	},
	emits: ['showCard'],
	setup(props, {emit}){
	  function show(cardData){
		  emit('showCard', cardData)
	  }
	  return {
		  show
	  }
	},
	methods:{
		formatDate(dateString){
			return moment(dateString).format('MMM DD hh:mm')
		},
		async openAltShare(){

			const modal = await modalController
				.create({
					component: FallbackShare,
					componentProps: {
						title: 'New Title'
					},
				})
			return modal.present()

		},
		async _sharePost(){
			if(navigator.share){
				navigator.share({
					title: `${this.post.caption}`,
					url: `${window.document.location.href}posts/${this.postId}`
				}).then(() => {
					console.log('content shared successfully')
				}).catch(console.error)
			} else {

        await Share.share({
          title: `${this.post.caption}`,
          url: `${window.document.location.href}posts/${this.postId}`,
        });

				this.openAltShare()
			}
		}
	}
}
</script>
<style scoped lang="scss">

@media only screen and (min-width:1024px) {
	ion-card {
		width: 25vw !important;
	}
}

.pointer {
  cursor: pointer;
}

ion-card {
	margin: 6vw 0;
	border: 2px solid black;
	width: calc(100vw - 36px);
}

.feed-img {

	.image {
		outline: 2px solid black;
		min-width: 100%;
	}

	ion-card-title {
		font-size: 4.5vw;
		line-height: 5.8vw;
	}

	ion-card-header {
		padding: 3vw;
	}
}
.feed-text {

  	ion-card-title {
		font-size: 6vw;
		line-height: 7.8vw;
  	}

  	ion-card-header {
		border-bottom: 2px solid black;
		padding: 3vw;
  	}
}

ion-col {
	padding: 3vw;
}

p {
	font-size: 3.73vw;
	line-height: 6.4vw;
}

.svg {
	height: 6.4vw;
	margin-right: 1vw;
}

.date {
	font-size: 3.5vw;
	line-height: 6.4vw;
}

 </style>
